<template>
  <b-card class="overflow-hidden container-card-work" body-class="body-card-v1" @click="$emit('click_action', data.card_action)">
    <div class="card-pending-color-background" :style="`background: ${data.background_left};`"></div>
    <div class="d-flex flex-column justify-content-between h-100">
      <div class="d-flex justify-content-between">
        <span class="text-find">{{ data.title }}</span>
       
        <div
          v-if="!icon_hidden"
          class="container-icon-collabs"
          :style="`background: ${data.backgrounds.selected};`"
          @mouseover="hoverAction(data.title)"
          @mouseout="hoverOutAction(data.title)"
        >
          <!-- <b-img :src="card.icon" class="icon-collabs" :ref="`icon_collabs_${index}`"></b-img> -->
          <img :src="require(`@/assets/images/icons/${data.icon}.svg`)" class="icon-collabs" :ref="`icon_collabs_${data.title}`"/>
        </div>
      </div>
      <p class="m-0 d-block avenir-medium f-11 mb-05">{{ data.text }}</p>
      <p class="m-0 style-subtitle"> {{ data.text_action }}</p>
    </div>
  </b-card>

</template>
    
<script>
import { BCard} from "bootstrap-vue";

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    color_div: {
      type: String,
      default: "card-pending-color-background"
    },
    icon_hidden: {
      type: Boolean,
      default: false
    }
  },
  name: "cardWork",
  components: {
    BCard,
  },
  methods: {
    hoverAction(index) {
      this.data.backgrounds.selected = this.data.backgrounds.container_hover;
      this.$refs[`icon_collabs_${index}`].style.filter = this.data.backgrounds.filter_svg;
    },
    hoverOutAction(index) {
      this.data.backgrounds.selected = this.data.backgrounds.container;
      this.$refs[`icon_collabs_${index}`].style.filter = '';
    },
  }
};
</script>
<style lang="scss" scoped>
.container-icon-collabs {
  height: auto;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon-collabs {
    height: 30px;
    width: 30px;
  }

  &:hover {
    transition: all 500ms;
  }
  &:not(:hover) {
    transition: all 500ms;
  }
}
.style-subtitle {
  color: #55a6c4;
  font-size: 1rem;
  font-weight: bold;
}
.f-11 {
  font-size: 12px;
}
.mb-05 {
  margin-bottom: 0.5em !important;
}
.text-find {
  color: #000000;
  font-size: 1.15rem;
  font-weight: bold;
}
.card-pending-color-background {
  height: 100%;
  width: 10px;
  position: absolute;
  top: 0;
  left: 0px;
}
.container-card-work {
  border: 0.0625rem solid rgba(222, 226, 230, 0.7);
  height: 100%;
  width: 100%;
}
.container-card-work:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(73, 194, 227, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset !important;
    background: #93c2df08;
    cursor: pointer;
}
.body-card-v1 {
  padding: 1em 1em 1em 24px;
}
.size-icon-card-v2 {
  width: 2.5rem;
  height: 2.5rem;
}
</style>